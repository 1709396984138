import React, { useRef } from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import ColBorder from "../../../../components/grid/col-border";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import MapWrapper from "../../../../components/map-wrapper/map-wrapper";
import StandardBlock from "../../../../components/standard-block/standard-block";
import "./contact-section.scss";
import LogoInImg from "./../../../../assets/linkedin.svg";
import StickyCol from "../../../../components/smooth-scrollbar/sticky-col";
import { setScrollTopHandler, setScrollTopHandlerValue } from "../../../../redux/callbacksState/callbacksState.actions";
import { useDispatch } from "react-redux";
import useWindowSize from "../../../../tools/useWindowSize";
import useWindowScroll from "../../../../tools/useWindowScroll";

const ContactSection: React.FC<any> = () => {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const size = useWindowSize();

  const scrollTopHandler = () => {
    dispatch(setScrollTopHandlerValue(0))
    dispatch(setScrollTopHandler());
  }

  useWindowScroll(1, ref);

  return (
    <section className="contact-section" ref={ref}>
      <Container>
        <Grid>
          <Row>
            <Col size={[4]} offset={[1, undefined, undefined, 0]}>
              <StickyCol>
                <StandardBlock>
                  <div className="sectionSubtitle">KONTAKT</div>
                </StandardBlock>
                <ColBorder variant={"dark"} height={1}>
                  <MapWrapper />
                </ColBorder>
              </StickyCol>
            </Col>
            {size.isMobile ?
              null
              : <>
                <Col size={2} offset={2}>
                  <ColBorder variant={"dark"} height={220} className="contact__info">
                    <StandardBlock>
                      <p><strong>Kancelaria Adwokacka<br />Agnieszka Mikołajska</strong></p>
                      <br />
                      <p>ul. Świętokrzyska 18 lok. 418<br />00-052 Warszawa</p>
                      <br />
                      <p>biuro@mikolajska.pl<br />+48 693 129  636</p>
                    </StandardBlock>
                  </ColBorder>
                </Col>
                <Col size={1} className="contact__in">
                  <ColBorder variant={"dark"} height={1}>
                    <a href="https://www.linkedin.com/in/adwokat-agnieszka-mikołajska" target="_blank" rel="noopener noreferrer">
                      <img src={LogoInImg} alt="Linkedin" />
                    </a>
                  </ColBorder>
                </Col>
                <Col size={1} className="contact__top">
                  <ColBorder variant={"dark"} height={1}>
                    <div className={"arrowTopWrapper"} onClick={scrollTopHandler}>
                      <span className="arrowTop tk-franklin-gothic-urw">
                        {">"}
                      </span>
                    </div>
                  </ColBorder>
                </Col>
              </>}
          </Row>
          <br />
          {size.isMobile ?
            <Row className="mobile__contact">
              <Col size={[2, 2]} offset={[undefined, undefined, undefined, 0]}>
                <ColBorder variant={"borderless"} height={220} className="contact__info">
                  <StandardBlock>
                    <p><strong>Kancelaria Adwokacka<br />Agnieszka Mikołajska</strong></p>
                    <br />
                    <p>ul. Świętokrzyska 18 lok. 418<br />00-052 Warszawa</p>
                    <br />
                    <p>biuro@mikolajska.pl<br />+48 693 129  636</p>
                    <br />
                    <p>NIP: 9542676827</p>
                  </StandardBlock>
                </ColBorder>
              </Col>
              <Col size={[1]} offset={[1, undefined, undefined, 1]} className="contact__in contact__top">
                <ColBorder variant={"white"} height={1}>
                  <div className={"arrowTopWrapper"} onClick={scrollTopHandler}>
                    <span className="arrowTop tk-franklin-gothic-urw">
                      {">"}
                    </span>
                  </div>
                </ColBorder>
                <br />
                <ColBorder variant={"white"} height={1}>
                  <a href="https://www.linkedin.com/in/adwokat-agnieszka-mikołajska" target="_blank" rel="noopener noreferrer">
                    <img src={LogoInImg} alt="Linkedin" />
                  </a>
                </ColBorder>
              </Col>
            </Row>
            : null}
        </Grid>
      </Container>
    </section>
  )
}

export default ContactSection;