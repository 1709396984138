import React, { } from "react";
import "./team-section.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setActiveTeamMemberIndex } from "../../../../redux/callbacksState/callbacksState.actions";
import { select_activeTeamMemberIndex } from "../../../../redux/callbacksState/callbacksState.selectors";

interface Props {
  children: React.ReactNode;
  isLast?: boolean;
  isFirst?: boolean;

}

const TeamSwiperNav: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const activeTeamMemberIndex = useSelector(select_activeTeamMemberIndex, shallowEqual);
  const { isLast, isFirst, children } = props;

  const setPrev = () => {
    if (!isFirst) {
      dispatch(setActiveTeamMemberIndex(activeTeamMemberIndex - 1))
    }
  }

  const setNext = () => {
    if (!isLast) {
      dispatch(setActiveTeamMemberIndex(activeTeamMemberIndex + 1))
    }
  }

  return (
    <div className="team_member__nav">
      {isFirst && isLast ?
        <>
          <div className="name tk-franklin-gothic-urw">{children}</div>
        </> :
        <>
          <div className={`leftBtn${isFirst ? " disabled" : ""} tk-franklin-gothic-urw`} onClick={setPrev}>{"<"}</div>
          <div className="name tk-franklin-gothic-urw">{children}</div>
          <div className={`rightBtn${isLast ? " disabled" : ""} tk-franklin-gothic-urw`} onClick={setNext}>{">"}</div>
        </>
      }
    </div>
  )
}

export default TeamSwiperNav;