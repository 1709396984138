import React from "react";
import Col from "../../../../../components/grid/col";
import ColBorder from "../../../../../components/grid/col-border";
import Grid from "../../../../../components/grid/grid";
import Row from "../../../../../components/grid/row";
import StandardBlock from "../../../../../components/standard-block/standard-block";
import "./offer-item.scss";

interface Props {
  children?: React.ReactNode
}

const OfferItemF: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return (
    <div className="offer_member">
      <Grid>
        <Row gap={0}>
          <Col size={12}>
          {children ? children : null}
            <ColBorder variant={'dark'} className={"title"}>
              <StandardBlock>
                <div className="tk-franklin-gothic-urw">W szczególności doradzamy przy:</div>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'} height={220} className={"slowly"}>
              <StandardBlock>
                <p>tworzeniu wniosków o otwarcie postępowania restrukturyzacyjnego oraz ogłoszenie upadłości</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>zgłaszaniu przez wierzycieli swoich wierzytelności, składaniu sprzeciwów oraz innych środków zaskarżenia w toku postępowania</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>postępowaniach dotyczących odpowiedzialności członków zarządu za zobowiązania spółki kapitałowej</p>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>wyborze odpowiedniej formy restrukturyzacji</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>obronie dłużnika przed otwarciem postępowania restrukturyzacyjnego lub ogłoszeniem upadłości</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220} className={"slowly"}>
              <StandardBlock>
                <p>postępowaniach o zakaz prowadzenia działalności gospodarczej</p>
              </StandardBlock>
            </ColBorder>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default OfferItemF;