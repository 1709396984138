/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef, useState } from "react";
import "./collapsible-block.scss";
import { Collapse } from 'react-collapse';
import { useDispatch } from "react-redux";
import { setActiveOfferIndex, setResizeHandler } from "../../redux/callbacksState/callbacksState.actions";

export interface CollapsibleBlockProps {
  title: string,
  children: React.ReactNode;
  isOpenInit?: boolean;
  isSplited?: boolean;
  activeItem?: number | undefined;
  itemIndex?: number;
  setActiveItem?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const CollapsibleBlock: React.FC<CollapsibleBlockProps> = (props: CollapsibleBlockProps) => {
  const [isOpen, setIsOpen] = useState(props.isOpenInit ? true : false)
  const { title, children, isSplited, activeItem, itemIndex, setActiveItem } = props;
  const ref = useRef<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeItem !== itemIndex) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem])


  const handleClickTitle = () => {
    if (!isOpen) {
      // handleScrollToSection(ref, 110, true)
      dispatch(setActiveOfferIndex(itemIndex));
    }
    else{
      dispatch(setActiveOfferIndex(undefined));
    }

    setIsOpen(!isOpen);
    var setActiveItemClone = setActiveItem!;
    setActiveItemClone(itemIndex);

    
    setTimeout(() => {
      dispatch(setResizeHandler());
    }, 1000);
  }

  return (
    <div ref={ref} className={`collapsible-block${isOpen ? " collapsible-block--open" : ""}`}>
      <div className={`collapsible-block__title${isSplited ? ' isSplited' : ''}`} onClick={() => handleClickTitle()}>
        <span className="title">{title}</span>
      </div>
      <Collapse isOpened={isOpen} reversed={true}>
        <div className={`collapsible-block__content${isSplited ? ' isSplited' : ''}`}>
          {children}
        </div>
      </Collapse>
    </div>
  )
}

export default CollapsibleBlock;