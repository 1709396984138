import React from "react";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import "./mobile-fluent-grid.scss";

interface Props {
}

const MobileFluentGrid: React.FC<Props> = (props: Props) => {
  return (
    <div className="mobile-fluent-grid-bg">
      <Container>
        <Grid>
          <Row>
            <Col size={1} className="double-size"></Col>
            <Col size={1} className="double-size"></Col>
            <Col size={1} className="double-size"></Col>
            <Col size={1} className="double-size"></Col>
          </Row>
        </Grid>
      </Container>
    </div>
  )
}

export default MobileFluentGrid;
