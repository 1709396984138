import React, { useRef } from "react";
import Col from "../../../../components/grid/col";
import "./offer-section.scss";
import { select_activeOfferIndex } from "../../../../redux/callbacksState/callbacksState.selectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import OfferItemA from "./offer-items/offerItemA";
import OfferItemB from "./offer-items/offerItemB";
import OfferItemC from "./offer-items/offerItemC";
import OfferItemD from "./offer-items/offerItemD";
import OfferItemE from "./offer-items/offerItemE";
import OfferItemF from "./offer-items/offerItemF";
import ColBorder from "../../../../components/grid/col-border";
import StandardBlock from "../../../../components/standard-block/standard-block";
import { setActiveOfferIndex, setSectionCardIndex, setSectionIndex } from "../../../../redux/callbacksState/callbacksState.actions";

const OfferWhiteCol: React.FC<any> = () => {
  const refCard = useRef<any>();
  const dispatch = useDispatch();
  const activeOfferIndex = useSelector(select_activeOfferIndex, shallowEqual);

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  }

  const setActiveOfferIndexHandler = (index: number) => {
    dispatch(setActiveOfferIndex(index));
    dispatch(setSectionCardIndex(3));
    dispatch(setSectionIndex(3));
  }

  return (
    <>
      <Col forwardedRef={refCard} onClick={stopPropagation} size={[4, 3]} offset={[2, undefined, undefined, 1]} className={`offerSection__description offer__description`}>
        <div className={`col50`}>
          <div className={`offerSwiper offerSwiperDefault ${activeOfferIndex ? " " : " offerSwiper--open"} offer_member`} >
            <ColBorder variant={'dark'} className={"titleLink"} onClick={() => setActiveOfferIndexHandler(1)}>
              <StandardBlock>
                <div className="tk-operetta-8">Spory sądowe i&nbsp;arbitrażowe, mediacja</div>
              </StandardBlock>
            </ColBorder>

            <ColBorder variant={'dark'} className={"titleLink"} onClick={() => setActiveOfferIndexHandler(2)}>
              <StandardBlock>
                <div className="tk-operetta-8">Doradztwo korporacyjne</div>
              </StandardBlock>
            </ColBorder>

            <ColBorder variant={'dark'} className={"titleLink"} onClick={() => setActiveOfferIndexHandler(3)}>
              <StandardBlock>
                <div className="tk-operetta-8">Spory korporacyjne</div>
              </StandardBlock>
            </ColBorder>

            <ColBorder variant={'dark'} className={"titleLink"} onClick={() => setActiveOfferIndexHandler(4)}>
              <StandardBlock>
                <div className="tk-operetta-8">Umowy w&nbsp;obrocie gospodarczym</div>
              </StandardBlock>
            </ColBorder>

            <ColBorder variant={'dark'} className={"titleLink"} onClick={() => setActiveOfferIndexHandler(5)}>
              <StandardBlock>
                <div className="tk-operetta-8">Sprawy pracownicze i&nbsp;ubezpieczeń społecznych</div>
              </StandardBlock>
            </ColBorder>

            <ColBorder variant={'dark'} className={"titleLink"} onClick={() => setActiveOfferIndexHandler(6)}>
              <StandardBlock>
                <div className="tk-operetta-8">Restrukturyzacja i&nbsp;upadłość</div>
              </StandardBlock>
            </ColBorder>
          </div>
        </div>
        <div className="col50 details">
          <div className={`offerSwiper${activeOfferIndex === 1 ? " offerSwiper--open" : ""}`}>
            {activeOfferIndex === 1 ? <OfferItemA>
              <StandardBlock className={'title-standard-block'}>
                <div className="title">Spory sądowe i&nbsp;arbitrażowe, mediacja</div>
              </StandardBlock>
              <ColBorder variant={'dark'} className="showOnMobile">
                <StandardBlock>
                  <p>
                    Reprezentujemy klientów na wszystkich etapach postępowań sądowych i arbitrażowych. Analizujemy i przedstawiamy szanse powodzenia sprawy, aby ułatwić podjęcie właściwej decyzji biznesowej. Wspólnie z klientem ustalamy plan działań i harmonogram konkretnych czynności.
                        </p>
                </StandardBlock>
              </ColBorder>
            </OfferItemA> : null}
          </div>
          <div className={`offerSwiper${activeOfferIndex === 2 ? " offerSwiper--open" : ""}`}>
            {activeOfferIndex === 2 ? <OfferItemB>
              <StandardBlock className={'title-standard-block'}>
                <div className="title">Doradztwo korporacyjne</div>
              </StandardBlock>
              <ColBorder variant={'dark'} className="showOnMobile">
                <StandardBlock>
                  <p>
                    Zapewniamy kompleksową obsługę prawną w zakresie prawa spółek. Dzięki naszemu doświadczeniu pomagamy na wszystkich etapach prowadzenia działalności gospodarczej – przy zakładaniu spółek, w ich codziennym funkcjonowaniu, na końcu działalności. Przygotowujemy opinie i ekspertyzy prawne, które ułatwiają członkom organów podjęcie bezpiecznych decyzji.
                        </p>
                </StandardBlock>
              </ColBorder>
            </OfferItemB> : null}
          </div>
          <div className={`offerSwiper${activeOfferIndex === 3 ? " offerSwiper--open" : ""}`}>
            {activeOfferIndex === 3 ? <OfferItemC>
              <StandardBlock className={'title-standard-block'}>
                <div className="title">Spory korporacyjne</div>
              </StandardBlock>
              <ColBorder variant={'dark'} className="showOnMobile">
                <StandardBlock>
                  <p>
                    Zapewniamy obsługę prawną spółkom handlowym, wspólnikom, akcjonariuszom oraz członkom organów na każdym etapie sporu korporacyjnego. Przygotowujemy szczegółową strategię sporu z uwzględnieniem możliwych działań przeciwnika. Reprezentujemy wspólników i akcjonariuszy na zgromadzeniach, doradzamy przy zaskarżaniu uchwał i w negocjacjach w trakcie sporu. Reprezentujemy klientów w postępowaniach sądowych.
                        </p>
                </StandardBlock>
              </ColBorder>
            </OfferItemC> : null}
          </div>
          <div className={`offerSwiper${activeOfferIndex === 4 ? " offerSwiper--open" : ""}`}>
            {activeOfferIndex === 4 ? <OfferItemD>
              <StandardBlock className={'title-standard-block'}>
                <div className="title">Umowy w&nbsp;obrocie gospodarczym</div>
              </StandardBlock>
              <ColBorder variant={'dark'} className="showOnMobile">
                <StandardBlock>
                  <p>
                    Doradzamy w zakresie tworzenia i realizacji umów w obrocie gospodarczym, w szczególności w oparciu o przepisy prawa cywilnego, prawa handlowego i prawa autorskiego. Korzystając z naszego doświadczenia pomagamy w negocjowaniu najlepszych warunków biznesowych, przy uwzględnieniu należytego zabezpieczania interesów prawnych klienta.
                        </p>
                </StandardBlock>
              </ColBorder>
            </OfferItemD> : null}
          </div>
          <div className={`offerSwiper${activeOfferIndex === 5 ? " offerSwiper--open" : ""}`}>
            {activeOfferIndex === 5 ? <OfferItemE>
              <StandardBlock className={'title-standard-block'}>
                <div className="title">Sprawy pracownicze i&nbsp;ubezpieczeń społecznych</div>
              </StandardBlock>
              <ColBorder variant={'dark'} className="showOnMobile">
                <StandardBlock>
                  <p>
                    Świadczymy usługi z zakresu prawa pracy i ubezpieczeń społecznych na rzecz pracodawców i pracowników. Doradzamy w sprawach związanych z zatrudnieniem pracowników w obszarze indywidualnych stosunków pracy. Badamy dokumenty pracownicze i praktyki w sprawach związanych z zatrudnieniem pod kątem ich zgodności z przepisami prawa. Pomagamy w negocjacjach.
                        </p>
                </StandardBlock>
              </ColBorder>
            </OfferItemE> : null}
          </div>
          <div className={`offerSwiper${activeOfferIndex === 6 ? " offerSwiper--open" : ""}`}>
            {activeOfferIndex === 6 ? <OfferItemF>
              <StandardBlock className={'title-standard-block'}>
                <div className="title">Restrukturyzacja i&nbsp;upadłość</div>
              </StandardBlock>
              <ColBorder variant={'dark'} className="showOnMobile">
                <StandardBlock>
                  <p>
                    Świadczymy usługi z zakresu restrukturyzacji i upadłości po stronie dłużników
                    i wierzycieli. Pomagamy przedsiębiorcom i osobom fizycznym na każdym etapie postępowania restrukturyzacyjnego i upadłościowego. Dopasowujemy właściwe rozwiązania prawne do potrzeb biznesowych naszych klientów. Reprezentujemy klientów przed sądami upadłościowymi.
                        </p>
                </StandardBlock>
              </ColBorder>
            </OfferItemF> : null}
          </div>
        </div>
      </Col>
    </>
  )
}

export default OfferWhiteCol;