import React from "react";
import Col from "../../../../../components/grid/col";
import ColBorder from "../../../../../components/grid/col-border";
import Grid from "../../../../../components/grid/grid";
import Row from "../../../../../components/grid/row";
import StandardBlock from "../../../../../components/standard-block/standard-block";
import "./offer-item.scss";

interface Props {
  children?: React.ReactNode
}

const OfferItemB: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return (
    <div className="offer_member">
      <Grid>
        <Row gap={0}>
          <Col size={12}>
          {children ? children : null}
            <ColBorder variant={'dark'} className={"title"}>
              <StandardBlock>
                <div className="tk-franklin-gothic-urw">W szczególności doradzamy przy:</div>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'} height={220} >
              <StandardBlock>
                <p>wyborze odpowiedniej formy prawnej prowadzonej działalności gospodarczej</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>zakładaniu spółek, ich przekształcaniu, łączeniu lub likwidacji</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220} className={"slowly"}>
              <StandardBlock>
                <p>organizowaniu posiedzeń zarządów, rad nadzorczych, zgromadzeń wspólników i walnych zgromadzeń</p>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'} height={2} className={"slowly"}>
              <StandardBlock>
                <p>przygotowywaniu dokumentów wewnętrznych, w tym statutów, umów spółek, regulaminów, uchwał oraz innych aktów konstytuujących funkcjonowanie podmiotów gospodarczych i ich organów</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>zmianach w strukturze wspólników lub akcjonariuszy</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>postępowaniach przed sądami rejestrowymi i wieczystoksięgowymi</p>
              </StandardBlock>
            </ColBorder>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default OfferItemB;