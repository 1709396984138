import React from "react";
import FullImgBlock from "../../../../components/fullImg-block/fullImg-block";
import Col from "../../../../components/grid/col";
import "./team-section.scss";
import MikolajskaImg from "./../../../../assets/mikolajska.png";
import TeamMemberA from "./team-members/team-memberA";
import { select_activeTeamMemberIndex, select_sectionCard_index } from "../../../../redux/callbacksState/callbacksState.selectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setActiveTeamMemberIndex } from "../../../../redux/callbacksState/callbacksState.actions";
import TeamSwiperNav from "./team-swiper-nav";

const TeamWhiteCol: React.FC<any> = () => {
  const activeSectionCard = useSelector(select_sectionCard_index, shallowEqual);
  const activeTeamMemberIndex = useSelector(select_activeTeamMemberIndex, shallowEqual);
  const dispatch = useDispatch();

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  }

  return (
    <Col size={[4, 4, 4, 3]} offset={[2, undefined, undefined, 1]} className={`team__description`} onClick={stopPropagation}>
      <div className="col50">
        <div className={`hideOnMobile team_img${activeTeamMemberIndex !== undefined ? " team_img_open" : ""} ${activeSectionCard > 0 ? "hideOnCardOpen" : ""}`} onClick={() => { dispatch(setActiveTeamMemberIndex(activeTeamMemberIndex !== undefined ? undefined : 0)) }}>
          <FullImgBlock img={MikolajskaImg} />
        </div>
      </div>
      <div className={`col50`}>
        <div className={`teamSwiper${activeTeamMemberIndex === 0 ? " teamSwiper--open" : ""}`}>
          <div className="team_member">
            <TeamSwiperNav isFirst isLast>Agnieszka Mikołajska</TeamSwiperNav>
            <TeamMemberA />
          </div>
        </div>
        {/* <div className={`teamSwiper${activeTeamMemberIndex === 1 ? " teamSwiper--open" : ""}`}>
              <div className="team_member">
                <TeamSwiperNav>Kamil Stoch</TeamSwiperNav>
                <TeamMemberA />
              </div>
            </div>
            <div className={`teamSwiper${activeTeamMemberIndex === 2 ? " teamSwiper--open" : ""}`}>
              <div className="team_member">
                <TeamSwiperNav isLast>Adam Małysz</TeamSwiperNav>
                <TeamMemberA />
              </div>
            </div> */}
      </div>
    </Col>
  )
}

export default TeamWhiteCol;