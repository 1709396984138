import { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setSectionScrollIndex } from "../redux/callbacksState/callbacksState.actions";
import { select_sectionScroll_index } from "../redux/callbacksState/callbacksState.selectors";

export default function useWindowScroll(expectedIndex: number, ref: any) {
  const sectionScrollIndex = useSelector(select_sectionScroll_index, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    function handleScroll() {
      if (ref.current!.getBoundingClientRect().top < 340 && ref.current!.getBoundingClientRect().top > 161 - ref.current!.getBoundingClientRect().height) {
        if (sectionScrollIndex !== expectedIndex) {
          dispatch(setSectionScrollIndex(expectedIndex));
        }
      }
    }

    setInterval(() => {
      handleScroll();
    }, 1000);

    window.addEventListener("touchmove", handleScroll);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("touchmove", handleScroll);
      window.removeEventListener("scroll", handleScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

}