import React from "react";
import Col from "../../../../../components/grid/col";
import ColBorder from "../../../../../components/grid/col-border";
import Grid from "../../../../../components/grid/grid";
import Row from "../../../../../components/grid/row";
import StandardBlock from "../../../../../components/standard-block/standard-block";
import "./offer-item.scss";

interface Props {
  children?: React.ReactNode
}

const OfferItemA: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return (
    <div className="offer_member">
      <Grid>
        <Row gap={0}>
          <Col size={12}>
            {children ? children : null}
            <ColBorder variant={'dark'} className={"title"}>
              <StandardBlock>
                <div className="tk-franklin-gothic-urw">W szczególności zapewniamy:</div>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>opracowanie strategii sporu</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>podjęcie czynności przedsądowych</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220} className={"slowly"}>
              <StandardBlock>
                <p>przygotowanie pism procesowych na każdym etapie postępowań sądowych</p>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'} height={220} className={"slowly"}>
              <StandardBlock>
                <p>podjęcie czynności związanych z egzekucją orzeczeń</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={440}>
              <StandardBlock>
                <p>zastępstwo przed sądami wszystkich instancji</p>
              </StandardBlock>
            </ColBorder>
          </Col>
          {/* <Col size={6}>
            <ColBorder variant={'dark'} height={220} className={"slowly"}>
              <StandardBlock>
                <p>sporach o naprawienie szkody spółce (tzw. actio pro socio)</p>
              </StandardBlock>
            </ColBorder>
          </Col> */}
        </Row>
      </Grid>
    </div>
  )
}

export default OfferItemA;