import { useState, useEffect } from "react";

export default function useWindowSize() {
  function getSize() {
    return {
      scrollY: window.scrollY,
      isMobile: window.innerWidth <= 700 ? true : false,
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    setWindowSize(getSize());

    function handleResize() {
      setWindowSize(getSize());
    }
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}