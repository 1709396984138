import React from "react";
import Col from "../../../../../components/grid/col";
import ColBorder from "../../../../../components/grid/col-border";
import Grid from "../../../../../components/grid/grid";
import Row from "../../../../../components/grid/row";
import StandardBlock from "../../../../../components/standard-block/standard-block";
import "./offer-item.scss";

interface Props {
  children?: React.ReactNode
}

const OfferItemC: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return (
    <div className="offer_member">
      <Grid>
        <Row gap={0}>
          <Col size={12}>
            {children ? children : null}
            <ColBorder variant={'dark'} className={"title"}>
              <StandardBlock>
                <div className="tk-franklin-gothic-urw">W szczególności doradzamy przy:</div>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'} height={220} >
              <StandardBlock>
                <p>postępowaniach procesowych o ustalenie nieistnienia, stwierdzenie nieważności lub uchylenie uchwał organów spółek handlowych</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>sporach dotyczących wyłączenia wspólników, rozwiązania spółek oraz zakazu zbywania akcji lub udziałów</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220} className={"slowly"}>
              <StandardBlock>
                <p>sporach związanych z odpowiedzialnością członków organów spółek</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>sporach o naruszenie dóbr osobistych spółek oraz obecnych lub byłych członków organów</p>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'} height={2} className={"slowly"}>
              <StandardBlock>
                <p>postępowaniach przed sądami rejestrowymi, m.in. w sprawach o ujawnienie zmian na podstawie kwestionowanych uchwał, w przypadkach kwestionowania upoważnienia do zwołania zgromadzeń, w razie problemów z ujawnieniem wspólnikom lub akcjonariuszom informacji dotyczących działalności spółek</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>sporach między wspólnikami spółek osobowych, w tym dotyczących pozbawienia wspólnika prawa reprezentacji spółki oraz prowadzenia spraw spółki</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>sporach o naprawienie szkody spółce (tzw. actio pro socio)</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>postępowaniach dotyczących odpowiedzialności członków zarządu za zobowiązania spółki kapitałowej</p>
              </StandardBlock>
            </ColBorder>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default OfferItemC;