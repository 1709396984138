import React from "react";
import "./home-page.scss";
import Helmet from "react-helmet";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import AboutSection from "./sections/about-section/about-section";
import TeamSection from "./sections/team-section/team-section";
import OfferSection from "./sections/offer-section/offer-section";
import ContactSection from "./sections/contact-section/contact-section";
import OfferMobileSection from "./sections/offer-section/offer-mobile-section";
import PublicationsSection from "./sections/publications-section/publications-section";

const HomePage: React.FC<any> = () => {
  // const size = useWindowSize();
  // const [isMobile, setIsMobile] = useState((size.width <= 700) as boolean)
  // const resizeHandler = useSelector(select_resizeHandler, shallowEqual);

  // useEffect(() => {
  //   mobileCheck();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   mobileCheck();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [size.width, resizeHandler])

  // const mobileCheck = () => {
  //   if (size.width <= 700) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // }

  return (
    <PageWrapper className="home-page">
      <Helmet>
        <title>{'Mikołajska | Kancelaria Adwokacka'}</title>
      </Helmet>
      <AboutSection />
      <TeamSection />
      <OfferSection />
      <OfferMobileSection />
      <PublicationsSection />
      <ContactSection />

    </PageWrapper>
  )
}

export default HomePage;