import React, { useEffect, useRef } from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import "./offer-section.scss";
import { select_sectionCard_index } from "../../../../redux/callbacksState/callbacksState.selectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import StickyCol from "../../../../components/smooth-scrollbar/sticky-col";
import StandardBlock from "../../../../components/standard-block/standard-block";
import { setActiveOfferIndex, setSectionCardIndex, setSectionIndex } from "../../../../redux/callbacksState/callbacksState.actions";
import useWindowScroll from "../../../../tools/useWindowScroll";
import ColBorder from "../../../../components/grid/col-border";

const OfferMobileSection: React.FC<any> = () => {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const activeSectionCard = useSelector(select_sectionCard_index, shallowEqual);

  const openCard = () => {
    if (activeSectionCard > 0 || window.innerWidth > 700) {
      dispatch(setSectionCardIndex(0));
      dispatch(setSectionIndex(0));
    }
    else {
      dispatch(setSectionCardIndex(1));
      dispatch(setSectionIndex(3));

      setTimeout(() => {
        dispatch(setSectionCardIndex(2));
        dispatch(setSectionIndex(3));
      }, 250);
    }
  }

  useEffect(() => {
    if (activeSectionCard === 0) {
      dispatch(setActiveOfferIndex(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSectionCard])


  useWindowScroll(3, ref);

  return (
    <section className="offer_section offer_section_mobile showOnMobile" ref={ref} onClick={openCard}>
      <Container>
        <Grid>
          <Row>
            <Col size={[4]} offset={[1, undefined, undefined, 0]}>
              <StickyCol>
                <StandardBlock variant={"white"}>
                  <div className="sectionSubtitle">OFERTA</div>
                </StandardBlock>
                <ColBorder variant={"white"} fill="dark" height={'auto'}>
                  <StandardBlock variant={"white"}>
                    <div className="title">
                      Zapewniamy kompleksową obsługę prawną.
                    </div>
                  </StandardBlock>
                </ColBorder>
                <br/>
                <StandardBlock variant={"white"}>
                  <div className="subtitle__button">
                    <div className="subtitle">{"ZOBACZ WIĘCEJ >"}</div>
                  </div>
                </StandardBlock>
              </StickyCol>
            </Col>
            {/* {size.width >= 700 ?
              <OfferWhiteCol /> : null
            } */}
          </Row>
        </Grid>
      </Container>
    </section >
  )
}

export default OfferMobileSection;