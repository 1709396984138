import React from "react";
import Col from "../../../../components/grid/col";
import ColBorder from "../../../../components/grid/col-border";
import StandardBlock from "../../../../components/standard-block/standard-block";
import "./about-section.scss";

const AboutWhiteCol: React.FC<any> = () => {

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  }

  return (
    <Col size={[2, 3]} offset={[4, 3, 3, 1]} onClick={stopPropagation}>
      <ColBorder variant={"dark"} height={220} className="aboutUs__description">
        <StandardBlock>
          <p><strong>Kancelaria Adwokacka<br />Agnieszka Mikołajska</strong></p>
          <br />
          <p>ul. Świętokrzyska 18 lok.&nbsp;418<br />00-052 Warszawa</p>
          <br />
          <p>biuro@mikolajska.pl<br />+48 693 129  636</p>
          <br />
          <p>NIP: 9542676827</p>
        </StandardBlock>
      </ColBorder>
    </Col>
  )
}

export default AboutWhiteCol;