import React, { } from "react";
import SimpleMap from "./map-styled-wrapper";
import "./map-wrapper.scss";

interface Props {
  maxWidth?: number
}

const MapWrapper: React.FC<Props> = (props: Props) => {
  const { maxWidth } = props;

  return (
    <div className="mapWrapper" style={{ maxWidth: maxWidth ? maxWidth : '100%' }}>
      <SimpleMap />
    </div>
  )
}

export default MapWrapper;


