/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef } from "react";
// import { shallowEqual, useSelector } from "react-redux";
// import { select_resizeHandler } from "../../redux/callbacksState/callbacksState.selectors";
// import useWindowSize from "../../tools/useWindowSize";
import "./sticky-col.scss";


interface Props {
  children: React.ReactNode;
  align?: 'top' | 'center' | 'bottom'
}

const StickyCol: React.FC<Props> = (props: Props) => {

  const { align, children } = props;
  const refWrapper = useRef<any>();
  const refCol = useRef<any>();

  // const [wrapper, setWrapper] = useState(0)
  // const [column, setColumn] = useState(0)
  // const [diff, setDiff] = useState(0)
  // const [diffGap, setDiffGap] = useState(0)
  // const [padding, setPadding] = useState(0)


  // const size = useWindowSize();
  // const resizeHandler = useSelector(select_resizeHandler, shallowEqual);

  const skewConfig = {
    ease: 0.07,
    current: 0,
    previous: 0,
    rounded: 0,
    initDiff: 0,
    diffGap: 0
  } as any

  // useEffect(() => {
  //   console.log("test");
  //   setTimeout(() => {
  //     skewConfig.diffGap = Math.round(refWrapper.current!.getBoundingClientRect().height - refCol.current!.getBoundingClientRect().height);
  //     setDiffGap(skewConfig.diffGap);
  //     requestAnimationFrame(() => handleScroll(skewConfig));
  //   }, 300);

  // }, [size.height, resizeHandler])

  useEffect(() => {
    setTimeout(() => {
      skewConfig.initDiff = Math.round(refWrapper.current!.getBoundingClientRect().height - refCol.current!.getBoundingClientRect().height);
      skewConfig.diffGap = Math.round(refWrapper.current!.getBoundingClientRect().height - refCol.current!.getBoundingClientRect().height);
      // setWrapper(refWrapper.current!.getBoundingClientRect().height);
      // setColumn(refCol.current!.getBoundingClientRect().height);
      // setDiff(skewConfig.initDiff);
      requestAnimationFrame(() => handleScroll(skewConfig));
    }, 300);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  const recalculateInitDiff = () => {
    let tempGapDiff = skewConfig.initDiff - skewConfig.diffGap;

    if (tempGapDiff !== 0) {
      if (Math.abs(tempGapDiff) > 10) {
        if (skewConfig.initDiff > skewConfig.diffGap) {
          skewConfig.initDiff = skewConfig.initDiff - 9;
        }
        else if (skewConfig.initDiff < skewConfig.diffGap) {
          skewConfig.initDiff = skewConfig.initDiff + 9;
        }
      }
      else if (skewConfig.initDiff > skewConfig.diffGap) {
        skewConfig.initDiff = skewConfig.initDiff - 1;
      }
      else if (skewConfig.initDiff < skewConfig.diffGap) {
        skewConfig.initDiff = skewConfig.initDiff + 1;
      }
    }



  }

  const handleScroll = (skewConfigg: any) => {

    skewConfig.diffGap = Math.round(refWrapper.current!.getBoundingClientRect().height - refCol.current!.getBoundingClientRect().height);

    recalculateInitDiff();

    var minTopOffset = refWrapper.current!.offsetTop;
    skewConfig.current = 64 + window.scrollY - minTopOffset;
    if (skewConfigg.current <= 0) skewConfig.current = 0;
    skewConfig.previous += (skewConfigg.current - skewConfigg.previous) * skewConfigg.ease;
    skewConfig.rounded = Math.round(skewConfig.previous * 100) / 100;
    if (skewConfigg.rounded > skewConfigg.initDiff) skewConfig.rounded = skewConfigg.initDiff;

    // if (skewConfig.rounded <= skewConfig.initDiff)
    // refCol.current.style.paddingTop = `${skewConfig.rounded}px`;
    refCol.current.style.transform = `translate3d(0, ${skewConfig.rounded}px, 0)`;
    // setPadding(skewConfig.rounded);

    // console.log(skewConfig.initDiff, refCol.current.style.paddingTop)
    requestAnimationFrame(() => handleScroll(skewConfig));

    // else {
    //   setOffsetY((-(window.innerHeight - 100)) + window.pageYOffset - (refWrapper.current!.offsetTop - window.innerHeight));
    // }
  }






  return (
    <div className={`sticky-col-wrapper${align ? " align-" + align : ""}`} ref={refWrapper}>
      <div className="sticky-col" ref={refCol}>
        {/* wrapper: {wrapper} <br />
        column: {column} <br />
        diff: {diff} <br />
        diffGap: {diffGap} <br />
        padding: {padding}<br />
        <br /><br /> */}
        {children}
      </div>
    </div>
    // <div ref={ref} className={'smooth-parallax'} style={{ transform: `translateY(${offsetY * props.speed}px)` }}>
    //   {props.children}
    // </div>
  )
}

export default StickyCol;