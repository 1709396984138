import React, { ReactNode } from "react";
import "./navbar.scss";
import Container from "../container/container";
import Grid from "../grid/grid";
import Row from "../grid/row";
import Col from "../grid/col";
import LogoImg from "./../../assets/logo.svg";
import LogoInImg from "./../../assets/linkedin.svg";
import ColBorder from "../grid/col-border";

interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode
}

const Navbar: React.FC<Props> = () => {

  return (
    <div className="navbar">
      <Container>
        <Grid>
          <Row>
            <Col offset={[1, undefined, undefined, 0]} size={2} className={"navbar__logo"}>
              <img src={LogoImg} alt="LogoImg" />
            </Col>
            <Col size={1} offset={7} className={"navbar__in"}>
              <ColBorder variant={"dark"} height={1}>
                <a href="https://www.linkedin.com/in/adwokat-agnieszka-mikołajska" target="_blank" rel="noopener noreferrer">
                  <img src={LogoInImg} alt="Linkedin" />
                </a>
              </ColBorder>
            </Col>
          </Row>
        </Grid>
      </Container>
    </div>
  )
}

export default Navbar;