import React, { useState } from "react";
import Col from "../../../../components/grid/col";
import ColBorder from "../../../../components/grid/col-border";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import StandardBlock from "../../../../components/standard-block/standard-block";
import "./publications-section.scss";
import { useDispatch } from "react-redux";
import { setResizeHandler } from "../../../../redux/callbacksState/callbacksState.actions";

const PublicationsWhiteCol: React.FC<any> = () => {
  const [items, loadMore] = useState(0);
  const dispatch = useDispatch();

  const loadMoreHandler = () => {
    loadMore(items + 1);
    dispatch(setResizeHandler());
  }

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  }

  return (
    <Col onClick={stopPropagation} size={4} offset={2} className={`publications_whiteCol offer__description`}>
      <div className="offer_member__description">
        <Grid>
          <Row gap={0}>
            <Col size={12}>
              <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                <StandardBlock>
                  <p><a href="https://czasopisma.beck.pl/monitor-prawa-handlowego/aktualnosc/istotne-zmiany-w-kodeksie-spolek-handlowych/">A. Mikołajska, dr R. L. Kwaśnicki, Istotne zmiany w Kodeksie spółek handlowych, Monitor Prawa Handlowego nr 1/2019</a></p>
                  {/* <p className="subtitle">Francesco Olle</p>
                  <p className="italic">COO, Public Broadcasting Service</p> */}
                </StandardBlock>
              </ColBorder>
            </Col>
            <Col size={12}>
              <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                <StandardBlock>
                  <p>A. Mikołajska, K. Łuczejko,&nbsp;Dokonanie czynności prawnej przez spółkę z o.o. bez wymaganej umową spółki&nbsp;zgody wspólników jest ważne. Glosa do&nbsp;postanowienia Sądu Najwyższego z&nbsp;dnia 17.04.2015 r., sygn. I&nbsp;CSK 289/14, dodatek do Monitora Prawniczego nr&nbsp;19/2017</p>
                </StandardBlock>
              </ColBorder>
            </Col>
            <Col size={12}>
              <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                <StandardBlock>
                  <p><a href="https://czasopisma.beck.pl/monitor-prawa-handlowego/aktualnosc/wyrok-sn-z-1552014-r-ii-csk-44613-wwwsnpl/">A. Mikołajska (opracowanie), Odpowiedzialności członka zarządu nie uchyla umowa łącząca członków zarządu co do sposobu kierowania sprawami spółki, w szczególności ustalony umownie podział czynności (&hellip;). Glosa do wyroku Sądu Najwyższego z dnia 15.05.2014 r., II CSK 446/13, Monitor Prawa Handlowego</a></p>
                </StandardBlock>
              </ColBorder>
            </Col>
            <Col size={12}>
              <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                <StandardBlock>
                  <p><a href="https://czasopisma.beck.pl/monitor-prawa-handlowego/aktualnosc/wyrok-sn-z-2632014-r-v-csk-22013-sip-legalis/">A. Mikołajska (opracowanie), Zwoływanie zwyczajnego lub nadzwyczajnego zgromadzenia wspólników spółki z ograniczoną odpowiedzialnością należy - w zasadzie - do kompetencji zarządu (art. 235 &sect; 1 KSH) i wchodzi w zakres czynności związanych z prowadzeniem spraw spółki, a nie z jej reprezentacją. Glosa do wyroku Sądu Najwyższego z dnia 26.03.2014 r., V CSK 220/13, Monitor Prawa Handlowego</a></p>
                </StandardBlock>
              </ColBorder>
            </Col>
            <Col size={12}>
              <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                <StandardBlock>
                  <p><a href="https://czasopisma.beck.pl/monitor-prawa-handlowego/aktualnosc/wyrok-sn-z-1332013-r-iv-csk-22812/">A. Mikołajska (opracowanie), Znaczący i długo trwający konflikt między wspólnikami przesądza o niemożności osiągnięcia celu spółki i uzasadnia żądanie wspólnika o rozwiązanie spółki. Roszczenie o rozwiązanie spółki przysługuje wspólnikowi, jeżeli zostały spełnione przesłanki określone w art. 271 pkt 1 KSH, bez względu na &ndash; mające charakter zabezpieczający powództwo o wyłączenie wspólnika (art. 266 &sect; 1 KSH) &ndash; zawieszenie go w wykonywaniu praw udziałowych w spółce na podstawie art. 268 KSH. Glosa do wyroku Sądu Najwyższego z dnia 13.03.2013 r., IV CSK 228/12, Monitor Prawa Handlowego</a></p>
                </StandardBlock>
              </ColBorder>
            </Col>
            <Col size={12}>
              <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                <StandardBlock>
                  <p><a href="https://czasopisma.beck.pl/monitor-prawniczy/aktualnosc/uchwala-sn-z-2822013-r-iii-czp-10812/">A. Mikołajska (opracowanie), Wspólnik spółki jawnej, który spełnił świadczenie na rzecz wierzyciela tej spółki, może żądać od innego jej wspólnika zwrotu odpowiedniej części tego świadczenia wraz z kosztami procesu wytoczonego przeciwko niemu, chyba że pozwany wspólnik skutecznie podniesie zarzut wadliwego prowadzenia procesu przez wspólnika pozwanego przez wierzyciela. Glosa do uchwały Sądu Najwyższego z dnia 28.02.2013 r., III CZP 108/12, Monitor Prawniczy nr 22/2013</a></p>
                </StandardBlock>
              </ColBorder>
            </Col>

            {items > 0 ?
              <>
                <Col size={12}>
                  <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                    <StandardBlock>
                      <p><a href="https://czasopisma.beck.pl/monitor-prawa-handlowego/aktualnosc/wiecej-czasu-na-przerejestrowanie-spolki-z-rhb-do-krs/">A. Mikołajska, D. Kulgawczuk, Więcej czasu na przerejestrowanie spółki z RHB do KRS, Monitor Prawa Handlowego nr 05/2013</a></p>
                    </StandardBlock>
                  </ColBorder>
                </Col>
                <Col size={12}>
                  <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                    <StandardBlock>
                      <p>A. Mikołajska,&nbsp;<a href="http://prawobiznesu.com/polecenie-uchroni-podatkiem-spadkow-darowizn/" title="Polecenie uchroni przed podatkiem od spadków i darowizn">Polecenie uchroni przed podatkiem od spadków i darowizn</a>, PRAWO BIZNESU - LAW IN ACTION,&nbsp;<a href="http://prawobiznesu.com/">http://prawobiznesu.com/</a></p>
                    </StandardBlock>
                  </ColBorder>
                </Col>
                <Col size={12}>
                  <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                    <StandardBlock>
                      <p>A. Mikołajska, Zmiany w Regulaminie ASO, PRAWO BIZNESU - LAW IN ACTION,&nbsp;<a href="http://prawobiznesu.com/">http://prawobiznesu.com/</a></p>
                    </StandardBlock>
                  </ColBorder>
                </Col>
                <Col size={12}>
                  <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                    <StandardBlock>
                      <p>A. Mikołajska, Ulga na działalność badawczo-rozwojową &ndash; wyższe odpisy od 2018 r., PRAWO BIZNESU - LAW IN ACTION,&nbsp;<a href="http://prawobiznesu.com/">http://prawobiznesu.com/</a></p>
                    </StandardBlock>
                  </ColBorder>
                </Col>
                <Col size={12}>
                  <ColBorder variant={'dark'} height="auto" className={"slowly"}>
                    <StandardBlock>
                      <p>A. Mikołajska, Nowelizacja ustawy o ofercie: już bez &bdquo;małych wezwań&rdquo;, PRAWO BIZNESU - LAW IN ACTION,&nbsp;<a href="http://prawobiznesu.com/">http://prawobiznesu.com/</a></p>
                    </StandardBlock>
                  </ColBorder>
                </Col>
              </>
              : null}
            {items > 0 ? null :
              <Col size={12}>
                <ColBorder variant={'dark'} height={220} className={`loadMoreWrapper${items > 0 ? " loadMoreWrapper--allLoaded" : ""}`}>
                  <div className="loadMore" onClick={() => loadMoreHandler()}>+</div>
                </ColBorder>
              </Col>
            }
          </Row>
        </Grid>
      </div>
    </Col>
  )
}

export default PublicationsWhiteCol;