/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { disableScroll, enableScroll } from "../../tools/scrollControl";
import "./preloader.scss";

interface Props {
}

const Preloader: React.FC<Props> = () => {
  const [counter, setCounter] = useState(0)
  const [hideFlag, setHideFlag] = useState(false)

  useEffect(() => {
    disableScroll();
    (window as any).counter = 0;
    let interval: any;
    setTimeout(() => {
      interval = setInterval(() => {
        if ((window as any).counter < 100) {
          (window as any).counter = (window as any).counter + 1;
          setCounter((window as any).counter);
        }
        else {
          enableScroll();
          clearInterval(interval);
        }
      }, 20);
    }, 1050);

    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (counter >= 100) {
      setTimeout(() => {
        setHideFlag(true);
      }, 1050);
    }
  }, [counter])

  return (
    <div className={`preloader${counter >= 100 ? " isFinished" : ""}${hideFlag ? " isHidden" : ""}`}>
      <div className="box">
        <div className="rectsWrapper">
          <div className="rect rect--left"></div>
          <div className="rect rect--right"></div>
        </div>
        <div className="counter franklin-gothic-urw">{counter}%</div>
      </div>
    </div>
  )
}

export default Preloader;