import React, { useRef } from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import ColBorder from "../../../../components/grid/col-border";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import StandardBlock from "../../../../components/standard-block/standard-block";
import "./publications-section.scss";

import StickyCol from "../../../../components/smooth-scrollbar/sticky-col";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setSectionCardIndex, setSectionIndex } from "../../../../redux/callbacksState/callbacksState.actions";
import { select_sectionCard_index } from "../../../../redux/callbacksState/callbacksState.selectors";
import useWindowSize from "../../../../tools/useWindowSize";
import PublicationsWhiteCol from "./publications-white-col";
import useWindowScroll from "../../../../tools/useWindowScroll";

const PublicationsSection: React.FC<any> = () => {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const size = useWindowSize();

  const activeSectionCard = useSelector(select_sectionCard_index, shallowEqual);

  const openCard = () => {
    if (activeSectionCard > 0 || window.innerWidth > 700) {
      dispatch(setSectionCardIndex(0));
      dispatch(setSectionIndex(0));
    }
    else {
      dispatch(setSectionCardIndex(1));
      dispatch(setSectionIndex(4));

      setTimeout(() => {
        dispatch(setSectionCardIndex(2));
        dispatch(setSectionIndex(4));
      }, 250);
    }
  }

  useWindowScroll(4, ref);

  return (
    <section className="publications-section" ref={ref} onClick={openCard}>
      <Container>
        <Grid>
          <Row>
            <Col size={[4]} offset={[1, undefined, undefined, 0]}>
              <StickyCol>
                <StandardBlock variant={"white"}>
                  <div className="sectionSubtitle">PUBLIKACJE I GLOSY</div>
                </StandardBlock>
                <ColBorder variant={"white"} fill={'dark'} height="auto">
                  <StandardBlock variant={"white"}>
                    <div className="title">
                      Adwokat Agnieszka Mikołajska jest autorką i&nbsp;współautorką publikacji z zakresu prawa spółek i&nbsp;sporów korporacyjnych oraz glos do orzeczeń Sądu Najwyższego.
                    </div>
                  </StandardBlock>
                </ColBorder>
              </StickyCol>
            </Col>
            {size.width >= 700 ?
              <PublicationsWhiteCol />
              : null}
          </Row>
        </Grid>
      </Container>
    </section>
  )
}

export default PublicationsSection;