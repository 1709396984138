import React from "react";
import Col from "../../../../../components/grid/col";
import "./offer-item.scss";

const OfferItemNull: React.FC<any> = () => {

  return (
    <Col size={12}>
      {/* <ColBorder variant={'dark'} className={"slowly"} height={'auto'}>
      <StandardBlock>
        <div className="title">Obsługa prawna przedsiębiorców dotyczy prawa spółek i prawa cywilnego.</div>
      </StandardBlock>
    </ColBorder> */}
    </Col>
  )
}

export default OfferItemNull;