/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef } from "react";
import "./smooth-scrollbar.scss";
import useWindowSize from './../../tools/useWindowSize';
import { select_resizeHandler, select_scrollTopHandler, select_scrollTopHandler_value, select_sectionCard_index } from "../../redux/callbacksState/callbacksState.selectors";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setScrollTopHandlerValue, setScrollY } from "../../redux/callbacksState/callbacksState.actions";

interface Props {
  children: React.ReactNode;
}

export const skewConfig = {
  ease: 0.07,
  current: 0,
  previous: 0,
  rounded: 0
}

const SmoothScrollbar: React.FC<Props> = (props: Props) => {
  const scrollContainer = useRef<any>();
  // const [offsetY, setOffsetY] = useState(0);

  const resizeHandler = useSelector(select_resizeHandler, shallowEqual);
  const scrollTopHandler = useSelector(select_scrollTopHandler, shallowEqual);
  const scrollTopHandlerValue = useSelector(select_scrollTopHandler_value, shallowEqual);

  // const activeSection = useSelector(select_section_index, shallowEqual);
  const activeSectionCard = useSelector(select_sectionCard_index, shallowEqual);

  const size = useWindowSize();
  const dispatch = useDispatch();
  // const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    requestAnimationFrame(() => skewScrolling());
    setInterval(() => {
      heightCheck();
    }, 1000);
    
    setTimeout(() => {
      heightCheck();
    }, 200);

    setTimeout(() => {
      dispatch(setScrollTopHandlerValue(0));
      window.scrollTo(0, 0);
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    heightCheck();
  }, [size.height, resizeHandler])

  useEffect(() => {
    if (window.innerWidth <= 700) {
      (window as any).activeSectionCard = activeSectionCard;
    }
    else {
      (window as any).activeSectionCard = 0;
    }
  }, [activeSectionCard])

  useEffect(() => {
    window.scrollTo(0, scrollTopHandlerValue);
    (window as any).slowScroll = true;
    setTimeout(() => {
      (window as any).slowScroll = false;
      dispatch(setScrollTopHandlerValue(0));
    }, 2500);
    return () => {
      dispatch(setScrollTopHandlerValue(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTopHandler])


  const heightCheck = () => {
    document.body.style.height = `${scrollContainer.current.getBoundingClientRect().height}px`;
  }

  const skewScrolling = () => {
    if ((window as any).activeSectionCard === 0) {
      skewConfig.current = window.scrollY;
      skewConfig.previous += (skewConfig.current - skewConfig.previous) * ((window as any).slowScroll === true ? 0.03 : skewConfig.ease);
      skewConfig.rounded = Math.round(skewConfig.previous * 100) / 100;
      const difference = skewConfig.current - skewConfig.rounded;
      const acceleration = difference / size.width;
      const velocity = +acceleration;
      const skew = velocity * 2;
      scrollContainer.current.style.transform = `translate3d(0, -${skewConfig.rounded}px, 0) skewY(${skew}deg)`;
    }
    else {
      scrollContainer.current.style.transform = `translate3d(0, -${skewConfig.rounded}px, 0) skewY(${0}deg)`;
      window.scrollTo(0, skewConfig.rounded);
    }
    if ((window as any).slowScroll === true && skewConfig.rounded < 1) {
      (window as any).slowScroll = false;
    }
    dispatch(setScrollY(skewConfig.rounded));
    requestAnimationFrame(() => skewScrolling());
  }

  return (
    <div className={'smooth-scrollbar-wrapper'}>
      <div ref={scrollContainer} className={'smooth-scrollbar'}>
        {/* //style={{ transform: `translateY(${-offsetY}px)` }}> */}
        {props.children}
      </div>
    </div>
  )


}

export default SmoothScrollbar;