


import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { select_section_index, select_sectionCard_index } from "../../redux/callbacksState/callbacksState.selectors";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import "./fluent-grid.scss";

interface Props {
  children: React.ReactNode;
}

const FluentGrid: React.FC<Props> = (props: Props) => {
  const { children } = props;
  
  const activeSection = useSelector(select_section_index, shallowEqual);
  const activeSectionCard = useSelector(select_sectionCard_index, shallowEqual);

  return (
    <div className={`fluent-grid${activeSection > 0  && activeSectionCard > 0 ? " slideLeft" : ""}`}>
      <div className={`fluent-grid-bg`}>
        <Container>
          <Grid>
            <Row>
              <Col size={1} className="double-size"></Col>
              <Col size={1} className="double-size"></Col>
              <Col size={1} className="double-size"></Col>
              <Col size={1} className="double-size"></Col>
              <Col size={1} className="desktop-only"></Col>
              <Col size={1} className="desktop-only"></Col>

              <Col size={1} className="double-size"></Col>
              <Col size={1} className="double-size"></Col>
              <Col size={1} className="double-size"></Col>
              <Col size={1} className="double-size"></Col>
              <Col size={1} className="desktop-only"></Col>
              <Col size={1} className="desktop-only"></Col>
            </Row>
          </Grid>
        </Container>
      </div>
      <div className="fluent-grid-content">
        {children}
      </div>
    </div>
  )
}

export default FluentGrid;
