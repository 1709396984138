import CallbacksStateTypes from "./callbacksState.types";

export const setIsGlobalToastFailedToFetchActive = (isActive: boolean) => {
  return {
    type: CallbacksStateTypes.TOAST_FAILEDTOFETCH_ACTIVATED,
    payload: isActive
  };
};

export const hideSlideOverlay = () => {
  return {
    type: CallbacksStateTypes.HIDE_SLIDE_OVERLAY_SUCCESS
  }
}

export const showSlideOverlay = (redirectTo: string) => {
  return {
    type: CallbacksStateTypes.SHOW_SLIDE_OVERLAY_SUCCESS,
    payload: redirectTo
  };
};

export const setResizeHandler = () => {
  return {
    type: CallbacksStateTypes.SET_RESIZE_HANDLER,
  }
}

export const setScrollTopHandler = () => {
  return {
    type: CallbacksStateTypes.SET_SCROLLTOP_HANDLER,
  }
}

export const setActiveOfferIndex = (index: number | undefined) => {
  return {
    type: CallbacksStateTypes.SET_ACTIVE_OFFERINDEX,
    payload: index
  }
}

export const setActiveTeamMemberIndex = (index: number | undefined) => {
  return {
    type: CallbacksStateTypes.SET_ACTIVE_TEAMMEMBERINDEX,
    payload: index
  }
}


export const setSectionIndex = (index: number | undefined) => {
  return {
    type: CallbacksStateTypes.SET_SECTION_INDEX,
    payload: index
  }
}

export const setSectionScrollIndex = (index: number | undefined) => {
  return {
    type: CallbacksStateTypes.SET_SECTIONSCROLL_INDEX,
    payload: index
  }
}

export const setSectionCardIndex = (index: number | undefined) => {
  return {
    type: CallbacksStateTypes.SET_SECTIONCARD_INDEX,
    payload: index
  }
}

export const setScrollY = (scrollY: number) => {
  return {
    type: CallbacksStateTypes.SET_SCROLLY,
    payload: scrollY
  }
}

export const setScrollTopHandlerValue = (value: number) => {
  return {
    type: CallbacksStateTypes.SET_SCROLLTOP_HANDLER_VALUE,
    payload: value
  }
}