
import React from "react";
import Col from "../../../../../components/grid/col";
import ColBorder from "../../../../../components/grid/col-border";
import Grid from "../../../../../components/grid/grid";
import Row from "../../../../../components/grid/row";
import StandardBlock from "../../../../../components/standard-block/standard-block";
import "./team-member.scss";

const TeamMemberA: React.FC<any> = () => {

  return (
    <div className="team-member">
      <Grid>
        <Row gap={0}>
          <Col size={12}>
            <ColBorder variant={'dark'} className={"slowly"}>
              <StandardBlock>
                <p>
                  Specjalizuje się w prawie cywilnym, prawie spółek i sporach korporacyjnych. W ramach wykonywanej praktyki prowadzi także sprawy z zakresu prawa pracy oraz prawa upadłościowego i restrukturyzacyjnego.
                </p>
                <br />
                <p>
                  Posiada szerokie doświadczenie w reprezentowaniu klientów w postępowaniach sądowych i arbitrażowych, w kształtowaniu ładu korporacyjnego, przygotowywaniu strategii i prowadzeniu sporów korporacyjnych, opracowywaniu i negocjowaniu umów, przeprowadzaniu audytów prawnych przedsiębiorstw (due diligence).
                </p>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'}>
              <StandardBlock>
                <p>
                  Ponad 10-letnie doświadczenie zdobywała świadcząc pomoc prawną na rzecz klientów biznesowych, m.in. z branż wydobywczej, transportowej, telekomunikacyjnej, budowlanej, turystycznej i sanitarnej.
                </p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} className={"slowly"}>
              <StandardBlock>
                <p>
                  Jest autorką i współautorką publikacji dotyczących prawa spółek i sporów korporacyjnych oraz glos do orzeczeń Sądu Najwyższego. Posługuje się językiem angielskim.
                </p>
              </StandardBlock>
            </ColBorder>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default TeamMemberA;