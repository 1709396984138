import React, { ReactNode } from "react";
import "./grid.scss";

interface Props {
  variant: "dark" | "white" | "borderless",
  fill?: "dark" | "white",
  className?: string,
  children: ReactNode;
  height?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 12 | 220 | 440 | 'auto',
  onClick?: any,
}

const ColBorder: React.FC<Props> = (props: Props) => {
  const { children, className, variant, height, fill, onClick } = props;
  return (
    <div onClick={onClick} className={`col-border col-border-${variant} col-fill-${fill ? fill : (variant === 'dark' ? "white" : "transparent")}${height || height === 'auto' ? " col-height-" + height : " col-height-default"}${className ? " " + className : ""}`}>
      {children}
    </div>
  )
}

export default ColBorder;