import React, { useRef } from "react";
import Container from "../../../../components/container/container";
import FullImgBlock from "../../../../components/fullImg-block/fullImg-block";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import "./team-section.scss";
import MikolajskaImg from "./../../../../assets/mikolajska.png";
import StickyCol from "../../../../components/smooth-scrollbar/sticky-col";
import TeamHero from "./team-hero";
import { select_activeTeamMemberIndex, select_sectionCard_index } from "../../../../redux/callbacksState/callbacksState.selectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setActiveTeamMemberIndex, setSectionCardIndex, setSectionIndex } from "../../../../redux/callbacksState/callbacksState.actions";
import useWindowSize from "../../../../tools/useWindowSize";
import TeamWhiteCol from "./team-white-col";
import useWindowScroll from "../../../../tools/useWindowScroll";

const TeamSection: React.FC<any> = () => {

  const ref = useRef<any>();
  const activeTeamMemberIndex = useSelector(select_activeTeamMemberIndex, shallowEqual);
  const dispatch = useDispatch();
  const size = useWindowSize();
  const activeSectionCard = useSelector(select_sectionCard_index, shallowEqual);

  const openCard = () => {
    if (activeSectionCard > 0 || window.innerWidth > 700) {
      dispatch(setActiveTeamMemberIndex(activeTeamMemberIndex !== undefined ? undefined : 0))
      dispatch(setSectionCardIndex(0));
      dispatch(setSectionIndex(0));
    }
    else {
      dispatch(setActiveTeamMemberIndex(0))
      dispatch(setSectionCardIndex(1));
      dispatch(setSectionIndex(2));

      setTimeout(() => {
        dispatch(setActiveTeamMemberIndex(0))
        dispatch(setSectionCardIndex(2));
        dispatch(setSectionIndex(2));
      }, 250);
    }
  }

  useWindowScroll(2, ref);

  return (
    <section className="team_section" ref={ref} onClick={openCard}>
      <Container>
        <Grid>
          <Row >
            <Col size={4} offset={[1, undefined, undefined, 0]}>
              <StickyCol align={'center'}>
                <TeamHero />
                <div className={`showOnMobile team_img${activeTeamMemberIndex !== undefined ? " team_img_open" : ""} ${activeSectionCard > 0 ? "hideOnCardOpen" : ""}`}>
                  <FullImgBlock img={MikolajskaImg} alt={"Adwokat Agnieszka Mikołajska"}/>
                </div>
              </StickyCol>
            </Col>

            {size.width >= 700 ?
              <TeamWhiteCol />
              : null}
          </Row>
        </Grid>
      </Container>
    </section>
  )
}

export default TeamSection;