import React from "react";
import Col from "../../../../../components/grid/col";
import ColBorder from "../../../../../components/grid/col-border";
import Grid from "../../../../../components/grid/grid";
import Row from "../../../../../components/grid/row";
import StandardBlock from "../../../../../components/standard-block/standard-block";
import "./offer-item.scss";

interface Props {
  children?: React.ReactNode
}

const OfferItemD: React.FC<Props> = (props: Props) => {
  const { children } = props;

  return (
    <div className="offer_member">
      <Grid>
        <Row gap={0}>
          <Col size={12}>
          {children ? children : null}
            <ColBorder variant={'dark'} className={"title"}>
              <StandardBlock>
                <div className="tk-franklin-gothic-urw">W szczególności oferujemy:</div>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'} height={220} className={"slowly"}>
              <StandardBlock>
                <p>przygotowanie lub opiniowanie umów</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>doradzanie przy wykonywaniu umów</p>
              </StandardBlock>
            </ColBorder>
          </Col>
          <Col size={[12, 12, 12]}>
            <ColBorder variant={'dark'} height={220}>
              <StandardBlock>
                <p>dopasowanie rodzajów zabezpieczeń do przedmiotu i celu umów</p>
              </StandardBlock>
            </ColBorder>
            <ColBorder variant={'dark'} height={220} className={"slowly"}>
              <StandardBlock>
                <p>reprezentację przed sądami powszechnymi i arbitrażowym w razie sporów dotyczących umów</p>
              </StandardBlock>
            </ColBorder>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default OfferItemD;