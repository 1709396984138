import CallbacksStateTypes from './callbacksState.types';

const INITIAL_STATE = {
  is_globalToastFailedToFetchActive: false as boolean,
  is_slideOverlayActive: {
    isActive: false as boolean,
    redirectTo: ''
  },
  resizeHandler: 0 as number,
  scrollTopHandler: 0 as number,
  scrollTopHandlerValue: 0 as number,
  activeOfferIndex: undefined as undefined | number,
  activeTeamMemberIndex: undefined as undefined | number,
  sectionIndex: 0 as number,
  sectionScrollIndex: 0 as number,
  sectionCardIndex: 0 as number,
  scrollY: 0 as number,
}

const callbacksStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CallbacksStateTypes.TOAST_FAILEDTOFETCH_ACTIVATED:
      return {
        ...state,
        is_globalToastFailedToFetchActive: action.payload
      }
    case CallbacksStateTypes.SHOW_SLIDE_OVERLAY_SUCCESS:
      return {
        ...state,
        is_slideOverlayActive: {
          isActive: true,
          redirectTo: action.payload
        }
      }
    case CallbacksStateTypes.HIDE_SLIDE_OVERLAY_SUCCESS:
      return {
        ...state,
        is_slideOverlayActive: {
          isActive: false,
          redirectTo: ''
        }
      }
    case CallbacksStateTypes.SET_ACTIVE_OFFERINDEX:
      return {
        ...state,
        activeOfferIndex: action.payload
      }
    case CallbacksStateTypes.SET_SCROLLY:
      return {
        ...state,
        scrollY: action.payload
      }
    case CallbacksStateTypes.SET_ACTIVE_TEAMMEMBERINDEX:
      return {
        ...state,
        activeTeamMemberIndex: action.payload
      }
    case CallbacksStateTypes.SET_RESIZE_HANDLER:
      return {
        ...state,
        resizeHandler: state.resizeHandler + 1
      }
    case CallbacksStateTypes.SET_SCROLLTOP_HANDLER:
      return {
        ...state,
        scrollTopHandler: state.scrollTopHandler + 1
      }
      case CallbacksStateTypes.SET_SCROLLTOP_HANDLER_VALUE:
        return {
          ...state,
          scrollTopHandlerValue: action.payload
        }
    case CallbacksStateTypes.SET_SECTION_INDEX:
      return {
        ...state,
        sectionIndex: action.payload
      }
    case CallbacksStateTypes.SET_SECTIONSCROLL_INDEX:
      return {
        ...state,
        sectionScrollIndex: action.payload
      }
    case CallbacksStateTypes.SET_SECTIONCARD_INDEX:
      return {
        ...state,
        sectionCardIndex: action.payload
      }
    default: {
      return state;
    }
  }
}

export default callbacksStateReducer;