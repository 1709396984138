import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ColBorder from "../../../../components/grid/col-border";
import StandardBlock from "../../../../components/standard-block/standard-block";
import { setActiveTeamMemberIndex, setResizeHandler } from "../../../../redux/callbacksState/callbacksState.actions";
import { select_activeTeamMemberIndex } from "../../../../redux/callbacksState/callbacksState.selectors";
import "./team-hero.scss";

const TeamHero: React.FC = () => {
  const activeTeamMemberIndex = useSelector(select_activeTeamMemberIndex, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setResizeHandler());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTeamMemberIndex]);

  return (
    <>
      <div className="team-hero">
        <StandardBlock variant="white">
          <div className="sectionSubtitle">O KANCELARII</div>
        </StandardBlock>
        <ColBorder variant="white" height={'auto'} className={`magic-col ${activeTeamMemberIndex !== undefined ? ' isOpen' : ''}`}>
          <StandardBlock variant="white">
            <div className="title">Właścicielką kancelarii jest adwokat Agnieszka Mikołajska.</div>
          </StandardBlock>
        </ColBorder>
        <StandardBlock variant={"white"} className={`hideOnMobile ${activeTeamMemberIndex !== undefined ? "hideOnDesktop" : ""}`}>
          <div className="subtitle__button" onClick={() => { dispatch(setActiveTeamMemberIndex(activeTeamMemberIndex !== undefined ? undefined : 0)) }}>
            <div className="subtitle">{"WIĘCEJ >"}</div>
          </div>
        </StandardBlock>
      </div>
    </>
  )
}

export default TeamHero;