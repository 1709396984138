import React from 'react';
import GoogleMapReact from 'google-map-react';
import { mapStylesJson } from './mapsStyle';

const AnyReactComponent = ({ text }: any) => <div className="markerWrapper">
  <div className="marker">
    <div className="marker__field">
      Świętokrzyska 18<br />00-052 Warszawa
    </div>
  </div>
</div>;

interface Props {
  maxWidth?: number
}

const SimpleMap: React.FC<any> = (props: any) => {

  const defaultProps = {
    center: {
      lat: 52.2360134,
      lng: 21.0112764
    },
    zoom: 15
  };

  return (
    // Important! Always set the container height explicitly
    <div className="map" style={{ width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBleohv4iNVDWKo4k8XhQIBkzqU5fPDrGQ" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          styles: mapStylesJson,
        }}
      >
        <AnyReactComponent
          lat={52.2360134}
          lng={21.0112764}
        />
      </GoogleMapReact>
    </div>
  );
}

export default SimpleMap;