import React, { useEffect, useRef } from "react";
import Container from "../../../../components/container/container";
import Col from "../../../../components/grid/col";
import Grid from "../../../../components/grid/grid";
import Row from "../../../../components/grid/row";
import "./offer-section.scss";
import { select_activeOfferIndex, select_scrollY } from "../../../../redux/callbacksState/callbacksState.selectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import OfferItemA from "./offer-items/offerItemA";
import StickyCol from "../../../../components/smooth-scrollbar/sticky-col";
import OfferHero from "./offer-hero";
import OfferItemNull from "./offer-items/offerItemNull";
import OfferItemB from "./offer-items/offerItemB";
import OfferItemC from "./offer-items/offerItemC";
import OfferItemD from "./offer-items/offerItemD";
import OfferItemE from "./offer-items/offerItemE";
import OfferItemF from "./offer-items/offerItemF";
import { setScrollTopHandler, setScrollTopHandlerValue } from "../../../../redux/callbacksState/callbacksState.actions";

const OfferSection: React.FC<any> = () => {
  const activeOfferIndex = useSelector(select_activeOfferIndex, shallowEqual);
  const scrollY = useSelector(select_scrollY, shallowEqual);

  const ref = useRef<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setScrollTopHandlerValue((scrollY + ref.current!.getBoundingClientRect().top) + 128));
      dispatch(setScrollTopHandler());
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOfferIndex])

  return (
    <section className="offer_section hideOnMobile" ref={ref}>
      <Container>
        <Grid>
          <Row>
            <Col size={4} offset={1} className={'collapsible-blocks-wrapper-col'}>
              <StickyCol>
                <OfferHero />
              </StickyCol>
            </Col>
            <Col size={[4, 4]} offset={2} className="offer__description">
              <br />
              <br />
              <br />
              <div className="col50">
                <div className={`offerSwiper offerSwiperDefault ${activeOfferIndex ? " " : " offerSwiper--open"}`}  >
                  <OfferItemNull />
                </div>
              </div>
              <div className="col50">
                <div className={`offerSwiper${activeOfferIndex === 1 ? " offerSwiper--open" : ""}`}>
                  <OfferItemA />
                </div>
                <div className={`offerSwiper${activeOfferIndex === 2 ? " offerSwiper--open" : ""}`}>
                  <OfferItemB />
                </div>
                <div className={`offerSwiper${activeOfferIndex === 3 ? " offerSwiper--open" : ""}`}>
                  <OfferItemC />
                </div>
                <div className={`offerSwiper${activeOfferIndex === 4 ? " offerSwiper--open" : ""}`}>
                  <OfferItemD />
                </div>
                <div className={`offerSwiper${activeOfferIndex === 5 ? " offerSwiper--open" : ""}`}>
                  <OfferItemE />
                </div>
                <div className={`offerSwiper${activeOfferIndex === 6 ? " offerSwiper--open" : ""}`}>
                  <OfferItemF />
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </Container>
    </section >
  )
}

export default OfferSection;