import "./App.scss";
import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { History } from 'history';
import { RoutePath } from "./route-paths";
import HomePage from "./framework/pages/home-page/home-page";
import { compose } from "redux";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import Navbar from "./framework/components/navbar/navbar";
import Footer from "./framework/components/footer/footer";
import FluentGrid from "./framework/components/fluent-grid/fluent-grid";
import SmoothScrollbar from "./framework/components/smooth-scrollbar/smooth-scrollbar";
import SectionCardOverlay from "./framework/components/section-card-overlay/section-card-overlay";
import Preloader from "./framework/components/preloader/preloader";

interface Props {
  match: any;
  history?: History;
}

const App: React.FC<Props> = () => {
  return (
    <>
      <SectionCardOverlay />
      <FluentGrid>
        <SmoothScrollbar>
          <Navbar />
          <Switch>
            <Route exact path={RoutePath.HOME} render={() => <HomePage />} />
          </Switch>
          <Footer />
        </SmoothScrollbar>
      </FluentGrid>
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      {/* <CookiePolicyPopup /> */}
      <Preloader />
    </>
  );
}

export default compose(
  withRouter,
  connect(null, null)
)(App);