import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import AboutWhiteCol from "../../pages/home-page/sections/about-section/about-white-col";
import { setActiveOfferIndex, setActiveTeamMemberIndex, setSectionCardIndex, setSectionIndex } from "../../redux/callbacksState/callbacksState.actions";
import { select_sectionCard_index, select_sectionScroll_index, select_section_index } from "../../redux/callbacksState/callbacksState.selectors";
import useWindowSize from "../../tools/useWindowSize";
import Container from "../container/container";
import Grid from "../grid/grid";
import Row from "../grid/row";
import MobileFluentGrid from "./mobile-fluent-grid";
import "./section-card-overlay.scss";
import LogoImg from "./../../assets/logo.svg";
import Col from "../grid/col";
import TeamWhiteCol from "../../pages/home-page/sections/team-section/team-white-col";
import OfferWhiteCol from "../../pages/home-page/sections/offer-section/offer-white-col";
import ClientsWhiteCol from "../../pages/home-page/sections/publications-section/publications-white-col";

interface Props {
}

const SectionCardOverlay: React.FC<Props> = (props: Props) => {
  const activeSectionCard = useSelector(select_sectionCard_index, shallowEqual);
  const activeSection = useSelector(select_section_index, shallowEqual);
  const activeSectionScroll = useSelector(select_sectionScroll_index, shallowEqual);

  const size = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!size.isMobile) {
      dispatch(setActiveOfferIndex(0));
      dispatch(setSectionCardIndex(0));
      dispatch(setSectionIndex(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size])


  const increseActiveSectionCard = (scrollSection: number) => {
    if(scrollSection === 2){
      dispatch(setActiveTeamMemberIndex(0))
    }
    dispatch(setSectionIndex(scrollSection));
    dispatch(setSectionCardIndex(activeSectionCard + 1));

  }

  const decreaseActiveSectionCard = () => {
    if (activeSectionCard === 3) {
      dispatch(setSectionCardIndex(2));
      dispatch(setActiveOfferIndex(0));
    }
    else if (activeSectionCard > 0) {
      dispatch(setSectionCardIndex(0));
    }
  }

  const decreaseActiveSectionCardHome = () => {
    dispatch(setSectionCardIndex(0));
  }


  const getSectonName = (index: number) => {
    if (index === 2) {
      return "O kancelarii";
    }
    if (index === 3) {
      return "Zobacz ofertę";
    }
    if (index === 4) {
      return "Zobacz publikacje";
    }
    if (index === 5 || index === 1) {
      return "Zadzwoń";
    }
  }

  return (
    <>
      <div className={`section-card-overlay${activeSectionCard > 0 ? " isOpen" : ""}`}>
        <div className="section-card-overlay--inner">
          <MobileFluentGrid />
          <div className="whiteCols">
            <Container>
              <Grid>
                <Row gap={0}>
                  <Col size={[1, 1, 1, 1]} offset={[4, 3, 3, 3]}>
                    <div className="logo">
                      <img src={LogoImg} alt="Logo" />
                    </div>
                  </Col>
                </Row>
                <Row gap={0}>
                  {activeSectionCard > 0 && activeSection === 1 ? <AboutWhiteCol /> : null}
                  {activeSectionCard > 0 && activeSection === 2 ? <TeamWhiteCol /> : null}
                  {activeSectionCard > 0 && activeSection === 3 ? <OfferWhiteCol /> : null}
                  {activeSectionCard > 0 && activeSection === 4 ? <ClientsWhiteCol /> : null}
                </Row>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
      <div className={`mobileBtn__Wrapper${activeSectionCard > 0 ? " isSecondOpen" : ""}${activeSectionCard > 2 ? " isThirdOpen" : ""} ${activeSectionScroll < 1 || activeSectionScroll > 5 ? " isHidden" : ""}`}>
        {activeSectionScroll === 1 || activeSectionScroll === 5 ?
          <div className="mobileBtn mobileBtn--first mobileBtn--call"> <a href="tel:+48693129636"><span>{getSectonName(activeSectionScroll)}</span><span className="arrow">{">"}</span></a></div>
          :
          <div className="mobileBtn mobileBtn--first" onClick={()=>increseActiveSectionCard(activeSectionScroll)}><span>{getSectonName(activeSectionScroll)}</span><span>{">"}</span></div>
        }
        <div className="mobileBtn mobileBtn--second" onClick={decreaseActiveSectionCard}>{"<"}</div>
        <div className="mobileBtn mobileBtn--third" onClick={decreaseActiveSectionCardHome}>{"<<"}</div>
      </div>
    </>
  )
}

export default SectionCardOverlay;