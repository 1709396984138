import React, { useState } from "react";
import { CollapsibleBlockProps } from "./collapsible-block";
import "./collapsible-block.scss";

interface Props {
  children: Array<React.ReactElement<CollapsibleBlockProps>>;
  isAcordeon?: boolean;
}

const CollapsibleBlocksWrapper: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const [activeItem, setactiveItem] = useState(undefined as undefined | number)
  return (
    <div className="collapsible-blocks-wrapper">

      {React.Children.map(children, (collapsibleBlock: React.ReactElement<CollapsibleBlockProps>, index: number) =>
        React.cloneElement(collapsibleBlock, {
          activeItem: activeItem,
          itemIndex: index + 1,
          setActiveItem: setactiveItem
        })
      )}
    </div>
  )
}

export default CollapsibleBlocksWrapper;