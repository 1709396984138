/* eslint-disable jsx-a11y/anchor-has-content */
import React, { } from "react";
import "./footer.scss";
import Col from "../grid/col";
import ColBorder from "../grid/col-border";
import Grid from "../grid/grid";
import Row from "../grid/row";
import StandardBlock from "../standard-block/standard-block";
import Container from "../container/container";
import useWindowSize from "../../tools/useWindowSize";

interface Props {
}

const Footer: React.FC<Props> = () => {
  const size = useWindowSize();

  return (
    size.isMobile ?
      <footer>
        <p>Kancelaria Adwokacka Agnieszka Mikołajska © 2020. Wszystkie prawa zastrzeżone.</p>
        <p>Projekt przygotowany przez SPNM.</p>
      </footer>
      :
      <footer>
        <Container>
          <Grid>
            <Row>
              <Col size={6}>
                <ColBorder variant='white' fill='dark'>
                  <Col size={10} offset={2}>
                    <StandardBlock>
                      <p>Kancelaria Adwokacka Agnieszka Mikołajska © 2020. Wszystkie prawa zastrzeżone.</p>
                    </StandardBlock>
                  </Col>
                </ColBorder>
              </Col>
              <Col size={6}>
                <ColBorder variant='dark' fill='white'>
                  <Col size={10}>
                    <StandardBlock align="right">
                      <p>Projekt przygotowany przez SPNM.</p>
                    </StandardBlock>
                  </Col>
                </ColBorder>
              </Col>
            </Row>
          </Grid>
        </Container>
      </footer>
  )
}

export default Footer;